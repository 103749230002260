.heart {
    &-icon {
        cursor: pointer;
        padding: 0.5rem;
        margin: -0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            animation-name: beating;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;

            &::before {
                color: $primary;
            }
        }

        &-container {
            position: relative;
        }
        &::after {
            display: block;
            content: '';
            position: absolute;
            height: 3px;
            width: 3px;
            border-radius: 50%;
            box-shadow:
                /* OffsetX OffsetY Blur Spread Color */
                0 0 0 2px transparent,
                0 0 0 2px transparent,
                0 0 0 2px transparent,
                0 0 0 2px transparent,
                0 0 0 2px transparent,
                0 0 0 1px transparent,
                0 0 0 1px transparent,
                0 0 0 1px transparent,
                0 0 0 1px transparent,
                0 0 0 1px transparent;
        }
        &::before {
            transition: color 0.15s ease-in-out;
            z-index: 2;
            color: lighten($dark, 35%);
        }
        &.is-checked {
            &::after {
                transition: /* Transition is here so when unchecked there's no transition */
                    box-shadow 0.75s ease-in-out,
                    height 1s ease-in-out,
                    width 1s ease-in-out,
                    opacity 1s ease-in-out;

                box-shadow:
                    /* OffsetX OffsetY Blur Spread Color */
                    0 20px 5px 0.5px $primary, /* BOTTOM Big Circle bottom*/
                    -20px -10px 0 0px $primary, /* BOTTOM Big Circle bottom left */
                    20px -10px 0 0px $primary, /* BOTTOM Big Circle bottom left */
                    25px 10px 0 0px $primary, /* BOTTOM Big Circle bottom left */
                    -20px 15px 0 0px $primary, /* BOTTOM Small Circle bottom left */
                    0 25px 0 0.5px $primary, /* BOTTOM Small Circle bottom*/
                    -15px -15px 0 0px $primary, /* BOTTOM Small Circle bottom left */
                    15px -15px 0 0px $primary, /* BOTTOM Small Circle bottom left */
                    20px 15px 0 0px $primary, /* BOTTOM Small Circle bottom left */
                    -20px 15px 0 0px $primary; /* BOTTOM Small Circle bottom left */

                height: 0px;
                width: 0px;
                opacity: 0;
            }

            &::before {
                animation-name: poppin;
                animation-duration: 1s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
                color: $primary;
            }
        }

    }
}

@keyframes beating {
    0% { transform: scale(1.1); }
    50% { transform: scale(0.9); }
    100% { transform: scale(1.1); }
}

@keyframes poppin {
    0% { transform: scale(0.5);}
    10% { transform: scale(1.75);}
    20% { transform: scale(0.7);}
    30% { transform: scale(1.35);}
    40% { transform: scale(0.9);}
    50% { transform: scale(1);}
    100% { transform: scale(1);}
}
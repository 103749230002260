$confirmed-duration: .5s;
$confirmed-delay: 2s;

.participant {
    @each $colorKey, $colorGradient in $clinic-gradients {
        &-list-item-#{$colorKey}.participant-checkbox-container{
            &:hover {
                border-color: map-get($colorGradient, dark);
            }
            & .participant-checkbox {
                background-color: map-get($colorGradient, dark);
            }
        }
        &-checkbox-input:checked~.participant-list-item-#{$colorKey} {
            border-color: map-get($colorGradient, dark);
        }
    }

    &-list-item {
        display: flex;
        align-items: center;
        margin-bottom: map-get($spacers, 2);
        padding: map-get($spacers, 3);
        transition: all $transition-duration ease;
        border-radius: $border-radius;
        background-color: $white;
        border: $border-color solid $border-width;

        &.is-new {
            background-color: lighten($success, 50%);
            animation-name: confirmed-bg;
            animation-duration: $confirmed-duration;
            animation-delay: $confirmed-delay;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            .participant-hcrNumber {
                color: $success ;
                animation-name: confirmed-text;
                animation-duration: $confirmed-duration;
                animation-delay: $confirmed-delay;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;

                i {
                    display: inline-block;
                    animation-name: confirmed-icon;
                    animation-duration: $confirmed-duration;
                    animation-delay: $confirmed-delay;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }

        .participant-hcrNumber { //default style (.text-muted.small)
            color: $text-muted;
            @include font-size($small-font-size);
            font-weight: $font-weight-normal;

            i {
                display: none; // just in case we always render the icon
            }
        }

    }

    &-checkbox-input {
        display: none;
        &:checked~.participant-list-item {
            & .participant-checkbox {
                opacity: 1;
            }
        }
    }

    &-checkbox-container {
        opacity: 1;
        cursor: pointer;

        &:hover .participant-checkbox {
            opacity: 0.6;
        }
        & .participant-checkbox {
            margin-left: auto;
            display: block;
            height: 2rem;
            line-height: 2rem;
            width: 2rem;
            flex: 0 0 2rem;
            text-align: center;
            border-radius: 50%;
            margin-left: auto;
            color: $white;
            opacity: 0;
            transition: $transition-base;
        }
    }

    &-linkMember-arrow {
        align-self: center;
        border-radius: 50%;
        height: 1.5em;
        width: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@keyframes confirmed-bg {
    0% {
        background-color: lighten($success, 50%);
    }

    100% {
        background-color: transparent;
    }
}
@keyframes confirmed-text {
    0% {
        color: $success;
    }

    100% {
        color: $text-muted;
    }
}
@keyframes confirmed-icon {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
.home {
    &-section-campaign {
        background: linear-gradient(to right, #171717, #313131);
        padding: map-get($spacers, 4);
        text-align: center;
        font-size: 4rem;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            font-size: 7.5rem;
        }

        & span {
            color: #fff;
            line-height: 1;
            margin-bottom: -0.2em;
            &.hockey{
                color: #C92127;
            }
        }
    }

    &-hero {
        background-color: $primary;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        &-text-big.fr {
            // fr patch to keep "hockey canada" in 1 line
            font-size: 40px;
        }
    }
    &-layer {
        &-assist {
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            & .text-shadow {
                text-shadow: 0 0 10px darken($primary, 20%);
            }
        }
        &-card {
            box-shadow: $box-shadow-sm;
            transition: transform 0.30s ease-in-out, box-shadow 0.30s ease-in-out;

            &-container:hover & {
                transform: translateY(-10px);
                box-shadow: $box-shadow;
            }

            &-overlay {
                top: auto;
                right: auto;
                left: 0;
                bottom: 0;
            }
        }
    }

    &-org-col{
        @include media-breakpoint-up(sm) {
            width: calc(100% / 5)
        }

        @include media-breakpoint-up(md) {
            width: calc(100% / 7)
        }
    }
}

// for more colors, add it manually because of how the lighten work..
.badge {
    &-danger {
        color: $danger;
        background-color: lighten($danger, 40%);
        border: $border-width solid $danger;
    }

    &-info {
        color: $info;
        background-color: lighten($info, 35%);
        border: $border-width solid $info;
    }

    &-success {
        color: $success;
        background-color: lighten($success, 50%);
        border: $border-width solid $success;
    }

    &-warning {
        color: darken($warning, 25%);
        background-color: lighten($warning, 45%);
        border: $border-width solid $warning;
    }

}
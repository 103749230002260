.text-decoration-underline {
    text-decoration: underline !important;
}

// these are custom HC fonts, do not touch these settings
.font-title {
    font-family: "Univers59UltraCondensed", "Roboto Condensed", "Arial Narrow", sans-serif !important;
    text-transform: uppercase !important;
    font-weight: 700;
}

// these are custom HC fonts, do not touch these settings
.font-title-small {
    font-family: "UniversThinUltraCondensed", "Roboto Condensed", "Arial Narrow", sans-serif !important;
    letter-spacing: .1em;
    text-transform: uppercase !important;
    font-weight: 300;
}

.text {
    @each $key, $value in $grid-breakpoints {
        @include media-breakpoint-up(#{$key}) {
            &-#{$key}-truncate {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
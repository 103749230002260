.register {
    &-search {
        @include media-breakpoint-up(lg) {
            margin-left: 1rem;
            margin-top: 42px; // aligned top with big title
        }
    }
    &-col {
        margin-bottom: $spacer;

        > div {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
    &-result {
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius-lg;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 280px;
        overflow: hidden;
        position: relative;
        transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            box-shadow: $box-shadow;
            transform: translateY(-10px);
        }

        &-title {
            font-size: $font-size-sm;
            margin-bottom: 0;
            padding-top: calc(20px + 0.5em);
        }

        &-header {
            position: relative;

            &::before {
                background-color: #e5e5e5;
                background-image: url("https://spordle-frontend-assets.s3.ca-central-1.amazonaws.com/hcrsite/register-result-header.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top;
                border-bottom: 1px solid $border-color;
                content: "";
                display: block;
                height: 80px;
            }

            &-inner {
                margin-top: -20px;
                padding-left: $spacer;
                padding-right: $spacer;
            }
        }

        &-stretched-link {
            align-items: center;
            background-color: rgba($black, 0.2);
            bottom: 0;
            color: $white;
            display: flex;
            font-size: 3rem;
            justify-content: center;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity 0.15s ease-in-out;
            z-index: 1;

            &::before {
                display: inline-block;
                transition: transform 0.15s ease-in-out;
                transform: translateY(10px);
                text-shadow: 0 0 5px rgba($black, 0.2);
            }

            &:hover {
                color: $white;
                text-decoration: none;
                opacity: 1;

                &::before {
                    transform: translateY(0);
                }
            }
        }
    }
}

.sticky .register-hero .hero-content {
    max-width: 415px; // quick hardcoded max-width to have a "quick" sticky animation
}

.branch-select{
    z-index: 100;
}
.hero {
    color: #fff;
    padding: 4rem 0;

    &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
        }
    }

    &-logo {
        margin-right: 1rem;
    }

    &-text {
        color: inherit;
        margin-bottom: 0;
        margin-top: 12px; // manually aligned vertically because HC's font has some margin-bottom

        &-small {
            display: block;
            font-size: 21px;
            letter-spacing: .1em;
            font-weight: 300;
        }

        &-big {
            display: block;
            font-size: 57px;
            letter-spacing: .05em;
            font-weight: 700;

            &.fr { // fr patch to keep "hockey canada" in 1 line
                font-size: 40px
            }
        }
    }


}

.sticky {
    .hero {
        transition: padding 0.1s ease-out;

        &-content {
            max-width: 100%; // we can overwrite this max width with another class to have a more direct animation
            flex-wrap: nowrap;
            transition: all 0.1s ease-out;
            opacity: 1;
        }
    }

    &.isSticky .hero {
        padding: 0;

        .hero-content {
            max-width: 0;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        .register-search {
            margin-left: 0;
            margin-top: 1rem;
            align-self: top;
        }
    }
}

.navbar {
    // forced height here only for debugging purposes.
    // if this causes a bug, please update the var because it affects how sticky stuff works in js
    height: $navbar-height;
}
.navbar-nav {
    color: #fff;

    .font-title {
        letter-spacing: 1px;
    }

    @include media-breakpoint-up(lg) {
        align-items: center
    }
}
.nav-profile-pill {
    height:40px;
    width:40px;
}
.custom-icon-burger {
    display: block;
    width: 100%;
    height: 2px;
    background-color: currentcolor;
    transition: background-color 0.15s ease-in-out;

    // burger used to have an animation, but its not relevent anymore
    // we currently only use this inside the mobile menu
    .is-open & {
        background-color: rgba(0,0,0,0);

        &::before {
            transform: rotate(45deg) translate(0);
        }
        &::after {
            transform: translate(0, -100%) rotate(-45deg);
        }
    }

    &-btn {
        width: $burger-btn-size;
        height: $burger-btn-size;
        display: block;

        &:focus,
        &:active,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
            outline: none;
        }
    }
    &::before,
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: currentcolor;
        transition: transform 0.15s ease-in-out;
    }
    &::before {
        transform: translate(0, -300%);
    }
    &::after {
        transform: translate(0, 200%);
    }
}

.search-arrow {
    &::before {
        transform: rotate(0);
        transition: transform 0.15s ease-in-out;
        display: inline-block;
    }

    &.is-open::before {
        transform: rotate(-180deg);
    }
}

.selectOrganisation {
    margin-top: map-get($spacers, 2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%; // asuming header is pos-relative
    box-shadow: $box-shadow-sm;
    z-index: $zindex-dropdown;
    max-height: 50vh;
}

.login-btn {
    color: #fff;

    &:hover {
        color: #fff;
        text-decoration: none;
        opacity: 0.6
    }
}


/* MOBILE ONLY*/
@include media-breakpoint-down(md) {
    .login-btn {
        font-size: $h5-font-size;
        color: $dark;
    }
    .mobile-nav-header {
        border-bottom: $border-width solid $border-color;
        padding: $spacer;
        margin-bottom: $spacer;
        display: flex;
        align-items: center;
        min-height: $mobile-navBar-height;
    }
    .nav {
        &-bar-toggler {
            position: relative;
            // z-index: $zindex-modal-backdrop + 1;
        }
        &bar {
            min-height: $mobile-navBar-height;

            &-nav {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                color: #000;
                background-color: #fff;
                width: 80vw;
                max-width: 400px;
                box-shadow: $box-shadow;
                transform: translateX(-100%);
                transition: transform 0.15s ease-in-out;

                &-container {
                    font-size: $h4-font-size;
                    background-color: transparent;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: $zindex-modal-backdrop;
                    transition: background-color 0.25s ease-in-out;
                    pointer-events: none;

                    &.is-open {
                        pointer-events: auto;
                        background-color: rgba(0,0,0,0.25);

                        & .navbar-nav {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
        &-item {
            color: inherit;
            padding: 0 $spacer;

            &:hover { // mobilehover
                background-color: $light;
            }
        }
        &-profile {
            display: flex;
            order: -1;
            padding-top: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;
            margin-bottom: $spacer;
            border-bottom: 1px solid $border-color;
            position: relative;

            &::after {
                content: "\F142";
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                font: normal normal normal 24px/1 "Material Design Icons";
                font-size: 1.5rem;
                top: 0;
                bottom: 0;
                text-rendering: auto;
                line-height: inherit;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transform: translate(0);
            }

            &-pill {
                height:55px;
                width:55px;
                margin-right: 10px;
            }
        }
    }
}
.hover-opacity:hover {
    opacity: .6;
}

.user-select-none {
    user-select: none;
}

.pointer-event-none {
    pointer-events: none;
}

.object-fit-contain {
    object-fit: contain
}
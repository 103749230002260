.rotate {
    transform: rotate(0);

    &-90 {
        transform: rotate(90deg);
    }
    &-180 {
        transform: rotate(180deg);
    }
}


.transition {
    transition: all $transition-duration ease;
}
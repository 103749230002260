.clinic-list-item {
    height: 100%;
    &-inner {
        height: 100%;
        transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
    }

    &:hover &-inner {
        box-shadow: $box-shadow;
        transform: translateY(-10px);
    }
    &-col > div {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

label.switch {
    $input-switch-color-off: $input-border-color;
    margin-bottom: 0;

    input[type=checkbox] {
        display: none;

        // switch's container
        &~.switch-toggler {
            cursor: pointer;
            background-color: $input-switch-color-off;
            width: $input-switch-width;
            padding: $input-switch-border-width;
            display: inline-block;
            vertical-align: middle;
            border-radius: 1000px;
            transition: all 0.2s ease-in-out;
        }

        // switch's circle
        &~.switch-toggler::before {
            content: "";
            display: block;
            background-color: $white;
            width: $input-switch-toggler-size;
            height: $input-switch-toggler-size;
            border-radius: 50%;
        }

        // onClick animations
        &:checked~.switch-toggler {
            background-color: $primary;
            padding-left: calc(#{$input-switch-width} - #{$input-switch-toggler-size} - #{$input-switch-border-width}); // container's width - whiteCircle's width - current padding
        }
    }
}
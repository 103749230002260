.userDisplay {
    display: flex;
    align-items: center;
    text-align: left;

    &-card {
        position: relative;
        background-color: #fff;
        padding: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 4);
        border-radius: $card-border-radius;
        border: $border-width solid $border-color;
        box-shadow: $box-shadow-sm;

        @include media-breakpoint-up(sm) {
            display: inline-flex;
            min-width: 300px;
        }

        &.userDisplay-hover {
            transition: all .25s ease;

            &:hover {
                cursor: pointer;
                transform: translateY(-4px) scale(1.01);
                box-shadow: 0 14px 24px rgba(62, 57, 107, 0.1);
            }
        }
    }

    &-container {
        margin-right: map-get($spacers, 2);

        &:last-child{
            margin-right: 0;
        }
    }

    &-title {
        color: $dark;
        //font-weight: $font-weight-bold;
    }

    &-subtitle {
        color: $text-muted;
        font-size: $small-font-size;
    }
}
.cookie-warning-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-fixed;
    background-color: $dark;
    box-shadow: $box-shadow;
    color: $light;
    transform: translateY(100%);
    animation-name: slideUp;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes slideUp {
    0% {transform: translateY(100%);}
    100% {transform: translateY(0);}
}
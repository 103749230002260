
.reset-a, .reset-a:hover {
    text-decoration: none;
    color: inherit;
}

.reset-ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.reset-btn {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    overflow: visible;
    text-transform: none;
    display: inline-block;
    outline: 0;
    color: inherit;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
    &::-moz-focusring {
        outline: 1px dotted ButtonText;
    }
}

.reset-legend {
    margin-bottom: 0;
    font-size: 1rem;
}

button:focus {
    outline: 0;
}

* {
    text-underline-position: under; // fixes text-decoration underline's spacing (not compatible IE / safari)
}

@use "sass:math";
@use "sass:map";

$font-family-base: 'Rubik', sans-serif;
$primary: #C92127;
$dark: #171717;
$light: #f2f2f2;
$success: #2EA949;
$warning: #FF8900;
$danger: #ED3C21;
$info: #3994ff;

$clinic-gradients: (
    0: (
        light: #4C9AF9,
        dark: #0058C3,
    ),
    1: (
        light: #EF3E21,
        dark: #AD220B,
    ),
    2: (
        light: #2CA74E,
        dark: #006E1E,
    ),
    3: (
        light: #9D4DBB,
        dark: #6A1A88,
    ),
    4: (
        light: #EC932B,
        dark: #CE7711,
    ),
    5: (
        light: #F953C6,
        dark: #B91D73,
    )
    // 6: (
    //     light: #ECBF2B,
    //     dark: #D3A50F,
    // )
);

$additionnal-theme-colors: ();

@each $colorKey, $colorGradient in $clinic-gradients {
    $additionnal-theme-colors: map.merge($additionnal-theme-colors, ("clinic-#{$colorKey}": map.get($colorGradient, dark)));
}

$clinic-status-colors: (
    open: $success,
    waiting: $warning,
    sold-out: $danger,
);

$container-lg-width: 1400px;
$container-sm-width: 768px;

$font-family-sans-serif: 'Rubik', sans-serif;
$font-weight-bold: 500;
$container-sm-width: 768px;

$navbar-height: 72px; // used for sticky stuffs, do not remove

$close-color: inherit;

$min-helper-font-size: 10;
$max-helper-font-size: 24;

$img-pill-size: 75px;
$img-pill-size-xs: math.div($img-pill-size, 2);
$img-pill-size-sm: math.div($img-pill-size, 1.5);
$img-pill-size-xl: $img-pill-size * 1.5;

$transition-duration: .35s; // collapse's default duration
$burger-btn-size : 45px;
$filter-dot-size: 10px;
$mobile-navBar-height: 65px; // navBar and mobile-nav-header must be same height

$input-switch-toggler-size: 0.75em !default;
$input-switch-width: 2em !default;
$input-switch-border-width: 3px !default;
$input-switch-color-off: gray !default;

$spordle-select-icon-color: rgba(0,0,0,0.2);
$spordle-select-icon-color-hover: rgba(0,0,0,0.5);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$theme-colors: map.merge($theme-colors, $additionnal-theme-colors);
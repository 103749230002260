@each $colorKey, $colorGradient in $clinic-gradients {
    $colorDark: map-get($colorGradient, dark); // Default color
    $colorLight: map-get($colorGradient, light);

    .bg-gradient-clinic-#{$colorKey} {
        background: linear-gradient(to right, $colorDark, $colorLight);
    }

    .fill-clinic-#{$colorKey} .cls-1, .fill-clinic-#{$colorKey} .cls-2 {
        fill: $colorDark !important
    }

    .fill-clinic-#{$colorKey} .cls-2 {
        opacity: 0.25
    }

    .color-clinic-#{$colorKey} {
        .dropdown-item.active, .dropdown-item:focus {
            background-color: $colorDark;
        }
        // radio & checkboxes
        &.custom-control .custom-control-input {
            &:checked~.custom-control-label::before {
                color: $colorDark;
                border-color: $colorDark;
                @include gradient-bg($colorDark);
            }

            &:focus~.custom-control-label::before {
                box-shadow: 0 0 0 $input-btn-focus-width rgba($colorDark, .25);
            }

            &:focus:not(:checked)~.custom-control-label::before {
                border-color: lighten($colorDark, 25%);
            }

            &:not(:disabled):active~.custom-control-label::before {
                background-color: lighten($colorDark, 35%);
                border-color: lighten($colorDark, 35%);
            }

            &:disabled {
                ~.custom-control-label {
                    color: $custom-control-label-disabled-color;

                    &::before {
                        background-color: $custom-control-indicator-disabled-bg;
                    }
                }
            }
        }

        // inputs
        &.form-control:focus,
        .form-control:focus,
        .spordleSelect .form-control:focus,
        .spordleSelect .form-control:focus-within {
            border-color: lighten($colorDark, 25%);
            box-shadow: 0 0 0 $input-btn-focus-width rgba($colorDark, .25);
        }

        .spordleSelect-option {
            &[data-selected=true] {
                background-color: $colorDark;
                color: white;
            }
            &[data-selected=true]:hover {
                background-color: lighten($colorDark, 10%);
            }
        }

        & .custom-control-input:disabled:checked ~ .custom-control-label::before {
            background-color: rgba($colorDark, 0.5);
            border-color: rgba($colorDark, 0.5);
        }

        & .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $colorDark;
            border-color: $colorDark;
        }
    }
}

@each $statusKey, $statusColor in $clinic-status-colors {
    .bg-#{$statusKey}-light {
        background-color: lighten($statusColor, 40%) !important;
    }
    .text-#{$statusKey} {
        color: $statusColor !important;
    }
}
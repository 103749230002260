@include media-breakpoint-down(md) {
    .clinicRegister-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: $zindex-fixed;
        border-top: $border-width solid $border-color;

        .card {
            border: none;
        }
    }
}
.max-vh-50 {
    max-height: 50vh !important;
}

.w {
    @each $key, $value in $grid-breakpoints {
        &-#{$key} {
            @each $size, $length in $sizes {
                &-#{$size} {
                    @include media-breakpoint-up(#{$key}) {
                        width: $length !important;
                    }
                }
            }
        }
    }
}
.waiver-helper {
    display: flex;
    align-items: center;
    background-color: white;
    padding: map-get($spacers, 1);
    margin-bottom: $spacer;
    border-radius: $card-border-radius;
    border: $border-width solid $border-color;

    @each $colorKey, $colorGradient in $clinic-gradients {
        &-#{$colorKey} {
            .waiver-helper-icon {
                color: map-get($colorGradient, dark);
            }
        }

    }

    &-icon {
        margin: 0 map-get($spacers, 2);
    }

}
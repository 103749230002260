@use "sass:map";

// phatt title font
@font-face {
    // these are custom HC fonts, do not touch these settings
    font-family: "Univers59UltraCondensed";
    src: url('../font/Univers59UltraCondensed.eot'); /* IE9 Compat Modes */
    src: url('../font/Univers59UltraCondensed.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/Univers59UltraCondensed.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/Univers59UltraCondensed.woff') format('woff'), /* Pretty Modern Browsers */
        url('../font/Univers59UltraCondensed.ttf')  format('truetype'); /* Safari, Android, iOS */
}
// smoll title font
@font-face {
    // these are custom HC fonts, do not touch these settings
    font-family: "UniversThinUltraCondensed";
    src: url('../font/UniversThinUltraCondensed.eot'); /* IE9 Compat Modes */
    src: url('../font/UniversThinUltraCondensed.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../font/UniversThinUltraCondensed.woff2') format('woff2'), /* Super Modern Browsers */
        url('../font/UniversThinUltraCondensed.woff') format('woff'), /* Pretty Modern Browsers */
        url('../font/UniversThinUltraCondensed.ttf')  format('truetype'); /* Safari, Android, iOS */
}
// title fallback font
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400&display=swap');

// default font
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');

@import 'custom/variables';
@import 'custom/reboot';
@import "bootstrap";
@import '~react-loading-skeleton/dist/skeleton.css';
@import 'icons/icons';

@import 'custom/mixins/buttons';
@import 'custom/mixins/circle';
@import 'custom/mixins/fontIcons';

@import 'custom/buttons';
@import 'custom/forms';
@import 'custom/navs';
@import 'custom/global';
@import 'custom/grid';
@import 'custom/utilities/background';
@import 'custom/utilities/clinic';
@import 'custom/utilities/colors';
@import 'custom/utilities/font';
@import 'custom/utilities/helpers';
@import 'custom/utilities/imgPill';
@import 'custom/utilities/position';
@import 'custom/utilities/sizing';
@import 'custom/utilities/text';
@import 'custom/utilities/transform';

// components last to overwrite the rest
@import 'custom/components/badge';
@import 'custom/components/clinicDetail';
@import 'custom/components/clinicCartParticipant';
@import 'custom/components/clinicCartModalities';
@import 'custom/components/clinicCartSelectItems';
@import 'custom/components/clinicCartWaivers';
@import 'custom/components/clinicList';
@import 'custom/components/clinicListFilter';
@import 'custom/components/clinicListHero';
@import 'custom/components/clinicListItem';
@import 'custom/components/clinicRegister';
@import 'custom/components/clinicSearch';
@import 'custom/components/cookieWarning';
@import 'custom/components/customAlert';
@import 'custom/components/datetime';
@import 'custom/components/dot';
@import 'custom/components/header';
@import 'custom/components/heartIcon';
@import 'custom/components/hero';
@import 'custom/components/home';
@import 'custom/components/layout';
@import 'custom/components/logoHCR';
@import 'custom/components/maintenance';
@import 'custom/components/network';
@import 'custom/components/overlayLoader';
@import 'custom/components/register';
@import 'custom/components/spordleSelect';
@import 'custom/components/sticky';
@import 'custom/components/switch';
@import 'custom/components/userDisplay';
@import 'custom/components/userImg';
@import 'custom/components/toast';
@import 'custom/components/perfect-scrollbar';

.modal:not(.show) .close {
    pointer-events: none;
}

.member-field i {
    font-style: normal;
}
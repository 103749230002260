.clinicList-hero {
    background-position: center;
    background-size: cover;
    color: #fff; // assuming it has a background-image
    padding: 2rem 0;
    transition: all 0.1s ease-out;


    @include media-breakpoint-up(lg) {
        padding: 4rem 0;
    }


}

.sticky .clinicList-hero .hero-content {
    max-width: 250px; // quick hardcoded max-width to have a "quick" sticky animation
}

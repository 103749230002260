.clinic-search-form-group {
    width: 100%;
}

@include media-breakpoint-up(sm) {
    .clinic-search-form-group {
        width: auto;
    }
}

.search-wrapper {
    position: relative;
}

.search-clear {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: calc(1.5em + .75rem + 2px);
    width: calc(1.5em + .75rem + 2px);
    font-size: 16px;
}
.network-logo {
    margin: 0 9px; // 9px so have logos in 1 line on tablet

    &-colour {
        display: none;
    }

    &:hover &-colour{
        display: inline;
    }
    &:hover &-grey{
        display: none;
    }
}
.filter {
    &-checkbox-container {
        padding: $input-btn-padding-y $input-btn-padding-x;
        background-color: $white;
        border: $input-border-width solid $input-border-color;
        cursor: pointer;

        &.disabled {
            background-color: $light;
            opacity: 0.5;
            cursor: default;
        }
    }

    &-checkbox {
        display:none;

        &:checked ~ &-container {
            // $input-btn-focus-box-shadow
            box-shadow: 0 0 0 2px lighten($primary, 20%);
        }
    }

    &-range {
        &-container {
            position: relative;
        }
        &-min, &-max {
            background: none;
            overflow: hidden;
            -webkit-appearance: none;
            border: $input-border-width solid $input-border-color;
            border-radius: 12px;
            @include form-control-focus();

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                background-color: $primary;
                height: 12px;
                width: 12px;
                border-radius: 12px;
                position: relative;
                z-index: 100;
            }
        }
        &-max {
            &::-webkit-slider-thumb {
                box-shadow: 0 0 0 1px $input-bg, -206px 0 0 200px $primary, 206px 0 0 200px $input-bg;
            }
        }
        &-min {
            position: absolute;
            top: 0;

            &::-webkit-slider-thumb {
                z-index: 110;
                box-shadow: 0 0 0 1px $input-bg, -206px 0 0 200px $input-bg;
            }
        }
    }

    &-dot {
        display: inline-block;
        height: $filter-dot-size;
        width: $filter-dot-size;
        border-radius: $filter-dot-size;
    }
}

@include media-breakpoint-down(md) {
    .filter {
        &-form {
            background-color: $white;
            position: absolute;
            top: 0;
            bottom: 0;
            transition: right 0.15s ease-in-out;
            height: 100%;
            overflow-y: auto;
            width: 100%;

            &-header {
                padding: 0.5rem 1rem;
                box-shadow: $box-shadow-sm;
            }

            &-content {
                padding: 1rem;
            }

            &-container {
                position: fixed;
                z-index: $zindex-tooltip;
                top: 0;
                right: 0;
                left: 0;
                height: 100vh;
                background-color: transparent;
                transition: background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
                transform: translateX(100%);

                &.is-open {
                    background-color: rgba(0,0,0,0.25);
                    transform: translateX(0);

                    & .filter-form {
                        box-shadow: $box-shadow;
                    }
                }
            }
        }
    }
}
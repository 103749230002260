.rdt{

    &::after {
        content: '\F678';
        padding: $input-padding-y $input-padding-x;
        position: absolute;
        right: 0;
        top: 1px; // border
        display: inline-block;
        font: normal normal normal 24px/1 'Material Design Icons';
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        opacity: 0.75;
        pointer-events: none;
    }
    input {
        padding-right: 40px;
    }
}